import React, { useState, useEffect } from "react";
import { BrowserView, MobileView } from "react-device-detect";

import "./countdown.css";

const Countdown = () => {
	const [timeLeft, setTimeLeft] = useState({
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0,
	});

	var countdownDate = new Date("2024-09-10 09:00").getTime(); // Imposta la data e l'ora del tuo evento

	useEffect(() => {
		// Funzione per aggiornare il countdown
		const updateCountdown = () => {
			const now = new Date().getTime();
			const distance = countdownDate - now;

			if (distance < 0) {
				// Se il countdown è terminato
				setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
				clearInterval(interval);
			} else {
				// Calcolo del tempo rimanente
				const days = Math.floor(distance / (1000 * 60 * 60 * 24));
				const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
				const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
				const seconds = Math.floor((distance % (1000 * 60)) / 1000);
				setTimeLeft({ days, hours, minutes, seconds });
			}
		};

		// Avvio dell'intervallo
		let interval = setInterval(updateCountdown, 1000);

		// Pulizia dell'intervallo
		return () => clearInterval(interval);
	}, [countdownDate]);

	return (
		<>
			<BrowserView>
				<div className="flex-w flex-c-m cd100 p-b-33">
					<div className="flex-col-c-m size2 bor1 m-l-15 m-r-15 m-b-20">
						<span className="l2-txt1 p-b-9 days">{timeLeft.days}</span>
						<span className="s2-txt1">Giorni</span>
					</div>

					<div className="flex-col-c-m size2 bor1 m-l-15 m-r-15 m-b-20">
						<span className="l2-txt1 p-b-9 hours">{timeLeft.hours}</span>
						<span className="s2-txt1">Ore</span>
					</div>

					<div className="flex-col-c-m size2 bor1 m-l-15 m-r-15 m-b-20 minutes">
						<span className="l2-txt1 p-b-9 minutes">{timeLeft.minutes}</span>
						<span className="s2-txt1">Minuti</span>
					</div>

					<div className="flex-col-c-m size2 bor1 m-l-15 m-r-15 m-b-20 seconds">
						<span className="l2-txt1 p-b-9 seconds">{timeLeft.seconds}</span>
						<span className="s2-txt1">Secondi</span>
					</div>
				</div>
			</BrowserView>
			<MobileView>
				<div className="flex-w flex-c-m cd100">
					<div className="flex-col-c-m size2 bor1 m-l-15 m-r-15 m-b-20">
						<span className="l2-txt1 p-b-9 days">{timeLeft.days}</span>
						<span className="s2-txt1">Giorni</span>
					</div>

					<div className="flex-col-c-m size2 bor1 m-l-15 m-r-15 m-b-20">
						<span className="l2-txt1 p-b-9 hours">{timeLeft.hours}</span>
						<span className="s2-txt1">Ore</span>
					</div>

					<div className="flex-col-c-m size2 bor1 m-l-15 m-r-15 m-b-20 minutes">
						<span className="l2-txt1 p-b-9 minutes">{timeLeft.minutes}</span>
						<span className="s2-txt1">Minuti</span>
					</div>

					<div className="flex-col-c-m size2 bor1 m-l-15 m-r-15 m-b-20 seconds">
						<span className="l2-txt1 p-b-9 seconds">{timeLeft.seconds}</span>
						<span className="s2-txt1">Secondi</span>
					</div>
				</div>
			</MobileView>
		</>
	);
};

export default Countdown;
