import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
	apiKey: "AIzaSyChL1zOXJoJbfG08cjIrNz_vnDq0RAY1qI",
	authDomain: "deana-coming-soon.firebaseapp.com",
	projectId: "deana-coming-soon",
	storageBucket: "deana-coming-soon.appspot.com",
	messagingSenderId: "276783539169",
	appId: "1:276783539169:web:830c5744a12d7decaf68a0",
	measurementId: "G-Y1DCV1PNEL",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { analytics };
