import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import { analytics } from "firebase.js";
import { logEvent } from "firebase/analytics";

import privacyPolicy from "../../pdf/privacy_policy.pdf";

import "./subscribe.scss";

const Subscribe = () => {
	const ref = React.createRef();

	const [responseMessage, setResponseMessage] = useState("");

	const onSubmit = async (inputData) => {
		const data = {
			email: inputData.sender,
			attributes: {
				nome: "",
				cognome: "",
			},
			listIds: [2], // Sostituisci con l'ID della tua lista
		};

		try {
			const isInResponse = await fetch(
				"https://api.brevo.com/v3/contacts/" + encodeURIComponent(data.email),
				{
					method: "GET",
					headers: {
						Accept: "application/json",
						"api-key": process.env.REACT_APP_BREVO_API_KEY,
					},
				}
			);

			// l'utente esiste già
			if (isInResponse.status === 200) {
				setResponseMessage("Sei già iscritta alla private list");

				logEvent(analytics, "iscrizione_ridondante", {
					email: data.email,
				});
			} else if (isInResponse.status === 404) {
				// l'utente non esiste, posso procedere alla iscrizione
				try {
					const response = await axios.post("https://api.brevo.com/v3/contacts", data, {
						headers: {
							"Content-Type": "application/json",
							"api-key": process.env.REACT_APP_BREVO_API_KEY,
						},
					});

					setResponseMessage(
						"La tua iscrizione alla private list è avvenuta correttamente"
					);

					logEvent(analytics, "iscrizione_effettuata", {
						email: data.email,
					});
				} catch (error) {
					setResponseMessage("Ops, qualcosa è andato storto. Riprova più tardi");
				}
			} else {
				setResponseMessage("Ops, qualcosa è andato storto. Riprova più tardi");
			}
		} catch (error) {
			setResponseMessage("Ops, qualcosa è andato storto. Riprova più tardi");
		}
	};

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		criteriaMode: "firstError", // Mostra solo il primo errore per campo
		defaultValues: {
			authorization: false,
			sender: "",
		},
	});

	return (
		<>
			<ErrorMessage
				key="authorizationError"
				errors={errors}
				name="authorization"
				render={({ message }) => <p className="text-danger">{message}</p>}
			/>

			<ErrorMessage
				key="senderError"
				errors={errors}
				name="sender"
				render={({ message }) => <p className="text-danger">{message}</p>}
			/>

			{responseMessage && (
				<p className="text-brevo" style={{ color: "#fff" }}>
					{responseMessage}
				</p>
			)}

			<form className="flex-w flex-c-m validate-form">
				<div className="wrap-input100 validate-input where1">
					<Controller
						key="sender"
						name="sender"
						control={control}
						rules={{
							required: "La email è obbligatoria",
							pattern: {
								value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
								message: "Formato indirizzo email errato",
							},
						}}
						render={({ field }) => (
							<input
								className="input100 placeholder0 s2-txt2"
								type="email"
								id="email"
								name="email"
								placeholder="Inserisci qui la tua email"
								ref={ref}
								{...field}
							/>
						)}
					/>

					<span className="focus-input100"></span>
				</div>

				<button
					className="flex-c-m size3 s2-txt3 how-btn1 trans-04 where1"
					onClick={handleSubmit(onSubmit)}>
					Iscriviti
				</button>

				<div className="privacy-policy-consent">
					<Controller
						key="authorization"
						name="authorization"
						control={control}
						rules={{ required: "Devi accettare la Privacy Policy" }}
						render={({ field }) => (
							<div>
								<input
									type="checkbox"
									id="styled-checkbox"
									name="styled-checkbox"
									checked={field.value}
									ref={ref}
									{...field}
								/>
								<label htmlFor="styled-checkbox">
									<div>
										Letta la
										<Link to={privacyPolicy} target="_blank" rel="noreferrer">
											Privacy Policy
										</Link>
										, presto il mio consenso per l'invio di comunicazioni promozionali (compresa la
										newsletter) da parte del sito a mezzo mail e riferite a prodotti propri.
									</div>
								</label>
							</div>
						)}
					/>
				</div>
			</form>
		</>
	);
};

export default Subscribe;
