import { Link } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";

import Countdown from "./components/Countdown";
import Subscribe from "./components/Subscribe";

import bg from "./images/bg.jpeg";
import logo from "./images/logo.png";

import cookiePolicy from "./pdf/cookie_policy.pdf";

import "./App.scss";

const App = () => {
	return (
		<>
			<div className="simpleslide100">
				<div
					className="simpleslide100-item bg-img1"
					sx="background-image: url('images/bg01.jpg');"
					style={{
						backgroundImage: `url("${bg}")`,
					}}></div>
			</div>

			<div className="size1 overlay1">
				<div className="size1 flex-col-c-m p-l-15 p-r-15 p-t-50 p-b-50">
					<img src={logo} width={400} className="logo" alt="Deana Gioielli" />
					<br />
					<br />
					<p className="m2-txt1 txt-center p-b-24">
						Una nuova era sta arrivando,
						<br />
						preparati a <span style={{ color: "#eacccc" }}>brillare</span> come mai prima
						d'ora.
					</p>
					<p className="m2-txt2 txt-center p-b-48">
						Pre-lancio <span style={{ color: "#eacccc" }}>esclusivo</span> dal{" "}
						<span style={{ color: "#eacccc" }}>10 Settembre</span> solo per le iscritte alla
						private list.
						<br />
						Iscriviti ora!
					</p>

					<BrowserView>
						<Countdown />

						<Subscribe />
					</BrowserView>

					<MobileView>
						<Subscribe />
						<br />
						<Countdown />
					</MobileView>

					<div className="disclaimer">
						<a
							href="https://www.instagram.com/deanagioielli/"
							target="_blank"
							rel="noreferrer">
							<i className="fa fa-instagram"></i>
							<span style={{ marginLeft: "8px" }}>Visita la pagina Instagram</span>
						</a>
					</div>
					<div className="footer">
						<div>
							&copy; {new Date().getFullYear()}&nbsp; Dojo S.r.l., P.IVA 13648840968. All
							Rights Reserved.
						</div>
						<div>|</div>
						<Link to={cookiePolicy} target="_blank" rel="noreferrer">
							<span>Cookie Policy</span>
						</Link>
					</div>
				</div>
			</div>
		</>
	);
};

export default App;
